import React, { useEffect, useRef, useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { ArrowForward } from '@styled-icons/material/ArrowForward';
import { TimelineImageType, TimelineTextType, TimelineType } from '../../../../../../types/timelineType';
import TimelineTextItem from './TimelineTextItem/TimelineTextItem';
import TimelineImageItem from './TimelineImageItem/TimelineImageItem';
import * as S from './Styles';

type TimelineProps = { block: TimelineType };

const Timeline = ({ block }: TimelineProps) => {
  const [timelineWrapperScroll, setTimelineWrapperScroll] = useState<'init' | 'scrolling' | 'finished'>('init');
  const timelineRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (timelineRef && timelineRef.current && typeof timelineRef !== 'undefined') {
      const listen = () => {
        if (!timelineRef.current || !timelineRef.current) return;

        const timelineScroll = timelineRef.current.scrollLeft.valueOf();
        const timelineScrollWidth = timelineRef.current.scrollWidth;

        const scrollPercentage = Math.round(
          (100 * timelineScroll) / (timelineScrollWidth - timelineRef.current.clientWidth),
        );

        if (scrollPercentage < 10) {
          setTimelineWrapperScroll('init');
        } else if (scrollPercentage === 10) {
          setTimelineWrapperScroll('scrolling');
        }
      };
      timelineRef.current.addEventListener('scroll', listen);

      // Adding drag and scroll functionality
      let isDown = false;
      let startX: number;
      let scrollLeft: number;

      timelineRef.current.addEventListener('mousedown', (e) => {
        if (!timelineRef?.current) return;
        isDown = true;
        timelineRef.current.classList.add('active');
        startX = e.pageX - timelineRef.current.offsetLeft;
        scrollLeft = timelineRef.current.scrollLeft;
      });

      timelineRef.current.addEventListener('mouseleave', () => {
        if (!timelineRef?.current) return;
        isDown = false;
        timelineRef.current.classList.remove('active');
      });
      timelineRef.current.addEventListener('mouseup', () => {
        if (!timelineRef?.current) return;
        isDown = false;
        timelineRef.current.classList.remove('active');
      });
      timelineRef.current.addEventListener('mousemove', (e) => {
        if (!timelineRef?.current) return;
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - timelineRef.current.offsetLeft;
        const walk = (x - startX) * 3;
        timelineRef.current.scrollLeft = scrollLeft - walk;
      });

      return () => window.removeEventListener('scroll', listen);
    }
    return undefined;
  }, []);

  return (
    <div className="Timeline">
      {(block.introText || block.title) && (
        <Grid>
          <GridRow>
            <GridCol s="12" m="6" l="9" xl="9">
              {block.title && (
                <Text variant={TextVariant.Header1} tag="h2">
                  {block.title}
                </Text>
              )}
              {block.introText && <S.IngressText variant={TextVariant.Ingress}>{block.introText}</S.IngressText>}
            </GridCol>
          </GridRow>
        </Grid>
      )}
      {block.timelineItems?.length > 0 && (
        <S.TimelineWrapper ref={timelineRef} className={timelineWrapperScroll}>
          {block.timelineItems.map((item) => {
            if (item._type === 'timelineTextItem')
              return <TimelineTextItem key={item._key} item={item as TimelineTextType} />;

            if (item._type === 'timelineImageItem')
              return <TimelineImageItem key={item._key} item={item as TimelineImageType} />;

            return null;
          })}
        </S.TimelineWrapper>
      )}
      <S.SwipeInformation className={timelineWrapperScroll}>
        <Text variant={TextVariant.ArticleTextLarge}>Sveip</Text>
        <ArrowForward size={32} />
      </S.SwipeInformation>
    </div>
  );
};

export default Timeline;
