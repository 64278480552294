import React from 'react';
import { Text, TextVariant } from '@naf/text';
import BlockContent from '../../../../block-content/BlockContent';
import { TimelineTextType } from '../../../../../../../types/timelineType';
import { TimelineGraph, TimelineTitle } from '../Styles';
import * as S from './Styles';

const TimelineTextItem = ({ item: { title, textBlock } }: { item: TimelineTextType }) => (
  <S.TimelineText>
    <TimelineTitle>{title && <Text variant={TextVariant.Header2}>{title}</Text>}</TimelineTitle>
    <TimelineGraph hasImage={false} />
    {textBlock && <BlockContent value={textBlock} />}
  </S.TimelineText>
);

export default TimelineTextItem;
